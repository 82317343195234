import axios from "axios";


axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (localStorage.getItem("data")) {
      let data = JSON.parse(localStorage.getItem("data"));
      config.headers["Authorization"] = `Bearer ${data.access_token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // if token expire logout user
    if (error.response && error.response.status == "401") {
      if (error.response.data.message == "Unauthenticated.") {
        localStorage.clear();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);
