/* import useAuth from "../../hooks/useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { routes } from "../../routes";
import { useEffect, useState } from "react";

const RequireAuth = ({ allowedPermissions }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState();
  useEffect(() => {
    //auth?.permissions?.find((permission) =>
    // allowedPermissions?.includes(permission?.name)
    //)
    const founded = auth?.permissions?.find((permission) =>
      allowedPermissions?.includes(permission?.name)
    );
    if (founded != undefined) {
      setHasPermission(founded);
    } else {
      setHasPermission(founded);
    }
    console.log(
      "====================================",
      auth?.permissions?.find((permission) =>
        allowedPermissions?.includes(permission?.name)
      )
    );
  }, []);

  return hasPermission ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate
      to={routes.dashboard.defaultPath}
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate
      to={routes.auth + routes.login}
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
 */
import useAuth from "../../hooks/useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  console.log("auth", auth);

  return auth?.user ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} state={{ from: location }} replace />
  );
};

export default RequireAuth;
