import useAuth from "../../hooks/useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const DeniedLogin = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return auth?.user ? (
    <Navigate to={"/dashboard"} state={{ from: location }} replace={true} />
  ) : (
    <Outlet />
  );
};

export default DeniedLogin;
